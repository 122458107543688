
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import accountx from '@/store/modules/accountx';

@Component({
	components: {},
})
export default class AccountCard extends Vue {
	@Prop({ default: 'gobi-9081' }) public readonly accountId!: string;
	@Prop({ default: 'GOBI Sdn Bhd' }) public readonly companyName!: string;
	@Prop({ default: '2019' }) public readonly companyRemark!: string;
	@Prop({ default: false }) public readonly unvisited!: boolean;

	public get isActive() {
		return accountx.currentAccountId === this.accountId;
	}
}
