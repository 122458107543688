
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AccountCard from '@/components/accounts/AccountCard.vue';
import userx from '@/store/modules/userx';
import { AccountMinimumData } from '@/store/models.def';
@Component({
	components: {
		AccountCard,
	},
})
export default class AllAccounts extends Vue {
	public isSeen: boolean = false;

	public mounted() {
		if (!localStorage.isSeen) {
			this.$bvModal.show('navigation-video');
		}
	}

	public toggleSeen() {
		this.isSeen = true;
		localStorage.isSeen = this.isSeen;
	}
	public get allAccounts() {
		// const dummy: AccountMinimumData[] = [];
		// dummy.push({
		// 	companyName: 'A VERY LONG NAME COMPANY SDN BHD',
		// 	companyRemark: '2016 is A very long year indeed',
		// 	accountId: 'A2016',
		// 	createdAt: 0,
		// 	creaatedBy: 'entah',
		// 	unvisited: true,
		// });
		// dummy.push({
		// 	companyName: 'B VERY LONG NAME COMPANY SDN BHD',
		// 	companyRemark: '2017 is A very long year indeed',
		// 	accountId: 'B2017',
		// 	createdAt: 0,
		// 	creaatedBy: 'entah',
		// 	unvisited: false,
		// });
		// dummy.push({
		// 	companyName: 'B VERY LONG NAME COMPANY SDN BHD',
		// 	companyRemark: '2018 is A very long year indeed',
		// 	accountId: 'B2018',
		// 	createdAt: 0,
		// 	creaatedBy: 'entah',
		// 	unvisited: true,
		// });
		// dummy.push({
		// 	companyName: 'C VERY LONG NAME COMPANY SDN BHD',
		// 	companyRemark: '2018 is A very long year indeed',
		// 	accountId: 'C2018',
		// 	createdAt: 0,
		// 	creaatedBy: 'entah',
		// 	unvisited: false,
		// });
		// dummy.push({
		// 	companyName: 'C VERY LONG NAME COMPANY SDN BHD',
		// 	companyRemark: '2019 is A very long year indeed',
		// 	accountId: 'C2019',
		// 	createdAt: 0,
		// 	creaatedBy: 'entah',
		// 	unvisited: false,
		// });
		// return dummy;
		return userx.myAccounts || [];
	}

	public get numAccounts() {
		return this.allAccounts.length;
	}
}
